/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';

import { capitalise } from '@neslotech/utils';

import { Employee } from '../../../interfaces/employee/employee.interface';
// import { FileData } from '../../../interfaces/config/file.interface';

import PayslipList from '../../payslips/list/PayslipList';
import Irp5List from '../../irp5s/list/Irp5List';
import TimesheetList from '../../timesheets/list/TimesheetList';
import ExpenseClaimList from '../../expense-claims/list/ExpenseClaimList';
import PerformanceReviewList from '../../performance-reviews/list/PerformanceReviewList';

// import { ReactComponent as WordIcon } from '../../../icons/word-icon.svg';
// import { ReactComponent as PDFIcon } from '../../../icons/pdf-icon.svg';

import RemovalProvider from '../../../providers/Removal.provider';
import RemoveExpenseClaimContainer from '../../../containers/human-resources/expense-claims/remove/RemoveExpenseClaim.container';
import RemovePayslipContainer from '../../../containers/human-resources/payslips/remove/RemovePayslip.container';
import RemoveTimesheetContainer from '../../../containers/human-resources/timesheets/remove/RemoveTimesheet.container';
// eslint-disable-next-line max-len
import RemovePerformanceReviewContainer from '../../../containers/human-resources/performance-reviews/remove/RemovePerformanceReview.container';

import InfoDetail from '../../clients/details/InfoDetail';
import DetailsCard from '../../card/DetailsCard';

import './employee-detail.scss';
import Tabs, { TabItem } from '../../tab/Tabs';
import RemoveIrp5Container from '../../../containers/human-resources/irp5s/remove/RemoveIrp5.container';
import EmployeeOtherDocumentsContainer from
'../../../containers/human-resources/employees/other-documents/EmployeeOtherDocuments.container';

interface Props {
  employee: Employee;
  onReload: () => void;
}

const EmployeeDetail = ({ employee, onReload }: Props) => {
  const currentYear = new Date().getFullYear();
  const leaveTitle = `Leave taken in ${currentYear}`;
  const leaveRemainingTitle = `Leave remaining in ${currentYear}`;

  const tabs: TabItem[] = useMemo(() => {
    return [
      {
        id: 0,
        title: 'Payslips',
        actionLabel: 'Add Payslip',
        actionPath: '/human-resources/payslips/add',
        Component: (
          <RemovalProvider>
            <PayslipList payslips={employee.payslips} loading={false} />
            <RemovePayslipContainer onRemoval={onReload} />
          </RemovalProvider>
        )
      },
      {
        id: 1,
        title: 'Irp5s',
        actionLabel: 'Add IRP5',
        actionPath: '/human-resources/irp5s/add',
        Component: (
          <RemovalProvider>
            <Irp5List irp5s={employee.irp5s} loading={false} />
            <RemoveIrp5Container onRemoval={onReload} />
          </RemovalProvider>
        )
      },
      {
        id: 2,
        title: 'Basic Info',
        Component: (
          <DetailsCard small>
            <article className="employee-card">
              <InfoDetail title="Employee Name" value={employee.full_name} />
              <InfoDetail title="Employee Email" value={employee.email} />
              <InfoDetail title="Employee Role" value={capitalise(employee.role)} />
              <InfoDetail title="ID Number" value={employee.id_number} />
              <InfoDetail title="Days of Leave" value={employee.days_of_leave ?? 0} />
              <InfoDetail
                title={leaveTitle}
                value={employee.days_of_leave - employee.leave_balance}
              />
              <InfoDetail title={leaveRemainingTitle} value={employee.leave_balance} />
              <InfoDetail title="Tax Number" value={employee.tax_number} />
            </article>
          </DetailsCard>
        )
      },
      {
        id: 3,
        title: 'Time Sheets',
        actionLabel: 'Add Time Sheet',
        actionPath: '/human-resources/timesheets/add',
        Component: (
          <RemovalProvider>
            <TimesheetList timesheets={employee.timesheets} loading={false} />
            <RemoveTimesheetContainer onRemoval={onReload} />
          </RemovalProvider>
        )
      },
      {
        id: 4,
        title: 'Expense Claims',
        actionLabel: 'Add Expense Claim',
        actionPath: '/human-resources/expense-claims/add',
        Component: (
          <RemovalProvider>
            <ExpenseClaimList expenseClaims={employee.expense_claims} loading={false} />
            <RemoveExpenseClaimContainer onRemoval={onReload} />
          </RemovalProvider>
        )
      },
      {
        id: 5,
        title: 'PDRs',
        actionLabel: 'Add PDR',
        actionPath: '/human-resources/performance-reviews/add',
        Component: (
          <RemovalProvider>
            <PerformanceReviewList
              performanceReviews={employee.performance_reviews}
              loading={false}
            />
            <RemovePerformanceReviewContainer onRemoval={onReload} />
          </RemovalProvider>
        )
      },
      {
        id: 6,
        title: 'Other Documents',
        // Component:
        //  (employee.id_documents ?? []).map((document: FileData | string) => (
        //   <UploadedFile file={document as FileData} />
        // ))
        Component: <EmployeeOtherDocumentsContainer />
      }
    ];
  }, [employee, onReload]);

  return (
    <article className="employee-detail">
      <header>
        <hr />
      </header>
      <main>
        <Tabs tabs={tabs} />
      </main>
    </article>
  );
};

// interface FileProps {
//   file: FileData;
// }

// const UploadedFile = ({ file }: FileProps) => (
//   <article
//     style={{ marginTop: 10 }}
//     className="uploaded-file"
//     onClick={() => window.open(file.url, '_blank')}
//   >
//     <section className="uploaded-file__name">
//       <PDFIcon />
//       <p>{file.name}</p>
//     </section>
//     <small>
//       Uploaded by {file.uploaded_by ?? 'you'} on {new Date(file.date).toLocaleDateString()}
//     </small>
//   </article>
// );

export default EmployeeDetail;
