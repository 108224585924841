import React, { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { capitalise, getClassNames, isEmpty, isUndefined } from '@neslotech/utils';

import { useAppDispatch, useAppSelector, useAuth } from '../../hooks';

import { Profile } from '../../interfaces/profile/profile.interface';
import { Results } from '../../interfaces/search/results.interface';
import { User } from '../../interfaces/user/user.interface';
import { Client } from '../../interfaces/client/client.interface';
import { Event } from '../../interfaces/event/event.interface';
import { ApiElement } from '../../types/api-element.interface';
import { Fund } from '../../interfaces/fund/fund.interface';
import { Investment } from '../../interfaces/investment/investment.interface';
import { DealSheet } from '../../interfaces/client/deal-sheet.interface';
import { Mandate } from '../../interfaces/client/mandate.interface';
import { Contact } from '../../interfaces/client/contact.interface';
import { Document } from '../../interfaces/document/document.interface';

import { RootState } from '../../reducers';

import { ClearResultsAction, SearchAction } from '../../actions/search/search.types';
import { SearchActions } from '../../actions/search/search.actions';

import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as LogoFilled } from '../../images/logo-filled.svg';
import { ReactComponent as ProfileIcon } from '../../icons/profile-icon.svg';
import { ReactComponent as CogIcon } from '../../icons/cog-icon.svg';
import { ReactComponent as LogoutIcon } from '../../icons/logout-icon.svg';

import Avatar from '../avatar/Avatar';
import { Dropdown } from '../dropdown/Dropdown';
import Input, { OnChangeType } from '../input/Input';
import { DropdownMenuItem } from '../dropdown/DropdownMenu';

import './header.scss';
import { Employee } from '../../interfaces/employee/employee.interface';
import { FileBlob } from '../../interfaces/document/file-blob.interface';
import { Category } from '../../interfaces/config/category.interface';
import { SubCategory } from '../../interfaces/config/sub_category.interface';

interface Props {
  profile?: Profile;
  authenticated?: boolean;
}

const Header = ({ authenticated, profile }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<SearchAction | ClearResultsAction> = useAppDispatch();

  const { onLogout } = useAuth();

  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const results: Results = useAppSelector(({ search_store }: RootState) => search_store.results);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        dispatch(SearchActions.search(debouncedSearchTerm));
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  const menuItems = useMemo(() => {
    return authenticated
      ? [
          {
            text: 'My Profile',
            icon: <ProfileIcon />,
            onClick: () => navigate('/profile/details')
          },
          {
            text: 'Change Password',
            icon: <CogIcon />,
            onClick: () => navigate('/profile/password')
          },
          { text: 'Logout', icon: <LogoutIcon />, onClick: onLogout }
        ]
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  const resultItems = useMemo<DropdownMenuItem[]>(() => {
    const clear = (path: string) => {
      dispatch(SearchActions.clearResults(() => navigate(path)));
    };

    return Object.entries(results ?? {})
      .filter(([_, entities]) => !isEmpty(entities))
      .reduce<DropdownMenuItem[]>(
        (accum: DropdownMenuItem[], [key, entities]: [string, ApiElement[]]) => {
          switch (key) {
            case 'events':
              accum.push(
                ...entities.map<DropdownMenuItem>((event) => ({
                  text: (
                    <>
                      <p>{(event as Event).name}</p>
                      <small style={{ color: '#939598' }}>Event</small>
                    </>
                  ),
                  onClick: () => clear(`/admin/events/${event.id}`)
                }))
              );
              break;
            case 'clients':
              accum.push(
                ...entities.map<DropdownMenuItem>((client) => ({
                  text: (
                    <>
                      <p>{(client as Client).name}</p>
                      <small style={{ color: '#939598' }}>Client</small>
                    </>
                  ),
                  onClick: () => clear(`/core/clients/${client.id}`)
                }))
              );
              break;
            case 'funds':
              accum.push(
                ...entities.map<DropdownMenuItem>((fund) => ({
                  text: (
                    <>
                      <p>{(fund as Fund).name}</p>
                      <small style={{ color: '#939598' }}>Fund</small>
                    </>
                  ),
                  onClick: () => clear(`/core/funds/${fund.id}`)
                }))
              );
              break;
            case 'investments':
              accum.push(
                ...entities.map<DropdownMenuItem>((investment) => ({
                  text: (
                    <>
                      <p>{(investment as Investment).name}</p>
                      <small style={{ color: '#939598' }}>Investment</small>
                    </>
                  ),
                  onClick: () =>
                    clear(
                      `/core/funds/${(investment as Investment).fund_id}/investments/${
                        investment.id
                      }`
                    )
                }))
              );
              break;
            case 'deal_sheets':
              accum.push(
                ...entities.map<DropdownMenuItem>((dealSheet) => ({
                  text: (
                    <>
                      <p>{(dealSheet as DealSheet).name}</p>
                      <small style={{ color: '#939598' }}>Deal Sheet</small>
                    </>
                  ),
                  onClick: () =>
                    clear(
                      `/core/clients/${(dealSheet as DealSheet).client_id}/deal-sheets/${
                        dealSheet.id
                      }`
                    )
                }))
              );
              break;
            case 'mandates':
              accum.push(
                ...entities.map<DropdownMenuItem>((mandate) => ({
                  text: (
                    <>
                      <p>{(mandate as Mandate).name}</p>
                      <small style={{ color: '#939598' }}>Mandate</small>
                    </>
                  ),
                  onClick: () => clear(`/core/clients/${(mandate as Mandate).client_id}/mandate`)
                }))
              );
              break;
            case 'contacts':
              accum.push(
                ...entities.map<DropdownMenuItem>((contact) => ({
                  text: (
                    <>
                      <p>{(contact as Contact).key_contact}</p>
                      <small style={{ color: '#939598' }}>Contact</small>
                    </>
                  ),
                  onClick: () => {
                    const contactInstance = contact as Contact;
                    if (contactInstance.client_id) {
                      clear(`/core/clients/${contactInstance.client_id}/contacts/${contactInstance.id}`);
                    } else {
                      clear(`/core/contacts?contactId=${contact.id}`);
                    }
                  }
                }))
              );
              break;
            case 'documents':
              accum.push(
                ...entities.map<DropdownMenuItem>((document) => ({
                  text: (
                    <>
                      <p>{(document as Document).name}</p>
                      <small style={{ color: '#939598' }}>Document</small>
                    </>
                  ),
                  onClick: () => clear(`/admin/documents/${(document as Document).id}`)
                }))
              );
              break;
            case 'employees':
              accum.push(
                ...entities.map<DropdownMenuItem>((employee) => ({
                  text: (
                    <>
                      <p>{`${(employee as Employee).first_name} ${
                        (employee as User).last_name
                      }`}</p>
                      <small style={{ color: '#939598' }}>Employee</small>
                    </>
                  ),
                  onClick: () => clear(`/human-resources/employees/${employee.id}`)
                }))
              );
              break;
            case 'categories':
              accum.push(
                ...entities.map<DropdownMenuItem>((category) => ({
                  text: (
                    <>
                      <p>{`${(category as Category).name}`}</p>
                      <small style={{ color: '#939598' }}>Category</small>
                    </>
                  ),
                  onClick: () =>  clear(`/admin/categories/${category.id}`)
                }))
              );
              break;
            case 'sub_categories':
              accum.push(
                ...entities.map<DropdownMenuItem>((sub_category) => ({
                  text: (
                    <>
                      <p>{`${(sub_category as SubCategory).name}`}</p>
                      <small style={{ color: '#939598' }}>SubCategory</small>
                    </>
                  ),
                  onClick: () =>  clear(`/admin/categories/${sub_category.category_id}/sub-categories/${sub_category.id}`)
                }))
              );
              break;
            case 'blobs':
              accum.push(
                ...entities.map<DropdownMenuItem>((blob) => ({
                  text: (
                    <>
                      <p>{`${(blob as FileBlob).filename}`}</p>
                      <small style={{ color: '#939598' }}>File Type</small>
                    </>
                  ),
                  onClick: () => window.open((blob as FileBlob).url, '_blank')
                }))
              );
              break;
            case 'users':
            default:
              accum.push(
                ...entities.map<DropdownMenuItem>((user) => ({
                  text: (
                    <>
                      <p>{`${(user as User).first_name} ${(user as User).last_name}`}</p>
                      <small style={{ color: '#939598' }}>User</small>
                    </>
                  ),
                  onClick: () => clear(`/admin/users/${user.id}`)
                }))
              );
          }

          return accum;
        },
        []
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  return (
    <nav className={getClassNames('header', { authenticated })}>
      <section className="header__search">
        {!authenticated ? <LogoFilled /> : <Logo onClick={goToDashboard} />}
        {authenticated && (
          <Dropdown menuItems={resultItems} open={!isUndefined(results)} isOpen={searchTerm !== ''}>
            <Input
              name="search"
              placeholder="Search..."
              onChange={({ search }: OnChangeType) => setSearchTerm(search as string)}
            />
          </Dropdown>
        )}
      </section>
      {authenticated && (
        <section className="header__actions">
          <Dropdown menuItems={menuItems}>
            <Avatar
              image={profile?.image}
              name={profile?.full_name}
              subtitle={capitalise(profile?.role)}
            />
          </Dropdown>
        </section>
      )}
    </nav>
  );
};

const useDebounce = (value: string, delay = 1000) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

export default Header;
