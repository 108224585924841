import React, { useEffect, useState } from 'react';

import { noOp } from '@neslotech/utils';

import { Event } from '../../interfaces/event/event.interface';
import { Contact } from '../../interfaces/client/contact.interface';
import { DealSheet } from '../../interfaces/client/deal-sheet.interface';
import { Category } from '../../interfaces/config/category.interface';

import { EventActions } from '../../actions/event/event.actions';
import { ContactActions } from '../../actions/contact/contact.actions';
import { DealSheetActions } from '../../actions/deal-sheet/deal-sheet.actions';
import { CategoryActions } from '../../actions/category/category.actions';
import { EmployeeActions } from '../../actions/employee/employee.actions';

import { useAppDispatch, useAppSelector } from '../../hooks';

import PageLayout from '../../components/layout/page/PageLayout';
import { Loader } from '../../components/loader/Loader';
import Dashboard from '../../components/dashboard/Dashboard';
import { Employee } from '../../interfaces/employee/employee.interface';
import { LeaveRequest } from '../../interfaces/employee/leave-request.interface';
import { LeaveRequestActions } from '../../actions/leave-request/leave-request.actions';

const DashboardContainer = () => {
  const dispatch = useAppDispatch();
  // const { id } = useParams<string>();

  const events: Event[] = useAppSelector(({ event_store }) => event_store.events);
  const leaveDates: LeaveRequest[] = useAppSelector(
    ({ leave_request_store }) => leave_request_store.leaveRequests
  );
  const categories: Category[] = useAppSelector(({ category_store }) => category_store.categories);
  const contacts: Contact[] = useAppSelector(({ contact_store }) => contact_store.contacts);
  const employees: Employee[] = useAppSelector(({ employee_store }) => employee_store.employees);
  const dealSheets: DealSheet[] = useAppSelector(
    ({ deal_sheet_store }) => deal_sheet_store.dealSheets
  );

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(CategoryActions.loadCategories(noOp));
    dispatch(EventActions.loadEvents(() => setLoading(false)));
    dispatch(ContactActions.loadContacts(false, () => setLoading(false)));
    dispatch(DealSheetActions.loadDealSheets(() => setLoading(false)));
    dispatch(EmployeeActions.loadEmployees(() => setLoading(false)));
    dispatch(LeaveRequestActions.loadLeaveRequests(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout title="Dashboard">
      {loading && <Loader filled />}
      {!loading && (
        <Dashboard
          events={events}
          leaveDates={leaveDates}
          contacts={contacts}
          dealSheets={dealSheets}
          categories={categories}
          employees={employees}
        />
      )}
    </PageLayout>
  );
};

export default DashboardContainer;
