import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import { AppState } from '../../../../types/state.interface';

import { useAppDispatch, useAppSelector, useAuth } from '../../../../hooks';

import RemovalProvider from '../../../../providers/Removal.provider';

import PageLayout from '../../../../components/layout/page/PageLayout';
import Irp5List from '../../../../components/irp5s/list/Irp5List';
import Button from '../../../../components/button/Button';
import { Irp5Actions } from '../../../../actions/irp5/irp5.actions';
import { Irp5 } from '../../../../interfaces/employee/irp5.interface';
import RemoveIrp5Container from '../remove/RemoveIrp5.container';
import { LoadIrp5sAction } from '../../../../actions/irp5/irp5.types';


const Irp5sContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<LoadIrp5sAction> = useAppDispatch();
  const irp5s: Irp5[] = useAppSelector(
    ({ irp5_store }: AppState) => irp5_store.irp5s
  );

  const [loading, setLoading] = useState<boolean>(true);
  const { isAdmin } = useAuth();

  useEffect(() => {
    dispatch(Irp5Actions.loadIrp5s(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      title="IRP5s"
      action={isAdmin && <Button label="Add IRP5" onClick={() => navigate('add')} />}
    >
      <RemovalProvider>
        <Irp5List irp5s={irp5s} loading={loading} />
        <RemoveIrp5Container />
      </RemovalProvider>
    </PageLayout>
  );
};

export default Irp5sContainer;
